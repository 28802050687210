import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import {
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import DatePicker from "react-datepicker";
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  eachDayOfInterval,
  format,
  formatISO,
  isSameDay,
  lastDayOfMonth,
  parse,
  startOfMonth,
} from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import { useLocalStorage } from "react-use";
import {
  Box,
  Button,
  Container,
  EditableTextarea,
  Flex,
  Heading,
  Input,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  UnorderedList,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const App = () => {
  const [data, setData] = React.useState([]);
  const [selectedMonthData, setSelectedMonthData] = React.useState([]);
  const [targetDate, setTargetDate] = React.useState(new Date().getDate() === 1 ? addDays(new Date(), -1) : new Date());
  const [schedule, setSchedule] = useLocalStorage("schedule");
  const [dataImportText, setDataImportText] = useState('');
  const [scheduleValue, setScheduleValue] = useState('');
  const importDataDialog = useDisclosure(false);

  const columns = React.useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "予定名", accessor: "title" },
      { Header: "開始", accessor: "start" },
      { Header: "終了", accessor: "end" },
      { Header: "色分け", accessor: "color" },
      { Header: "種類", accessor: "division" },
    ],
    []
  );

  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  };

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const {
    headerGroups,
    page,
    state: { pageIndex, pageSize },
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    selectedFlatRows,
    previousPage,
    setPageSize,
    getTableProps,
    getTableBodyProps,
  } = useTable(
    {
      columns,
      data: selectedMonthData,
      initialState: {
        pageSize: 30,
        hiddenColumns: ["id"],
        sortBy: [{ id: "division", desc: false }],
      },
      defaultColumn,
      sortTypes: {
        start: "datetime",
        end: "datetime",
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const handleForce = React.useCallback(
    (d) => {
      setData(
        d
          .slice(1)
          .filter((row) => row.length > 5)
          .map((row, i) => {
            return {
              id: i,
              title: row[0],
              start: formatISO(
                parse(`${row[1]} ${row[2]}`, "yyyy/MM/dd HH:mm:SS", new Date()),
                "yyyy/MM/dd HH:mm:SS"
              ),
              end: formatISO(
                parse(`${row[3]} ${row[4]}`, "yyyy/MM/dd HH:mm:SS", new Date()),
                "yyyy/MM/dd HH:mm:SS"
              ),
              color: row[20],
              division: schedule && row[0] in schedule ? schedule[row[0]] : "-",
            };
          })
      );
    },
    [schedule]
  );

  const handleClickDivisionButton = React.useCallback(
    (division) => {
      if (selectedFlatRows.length > 0) {
        const ids = selectedFlatRows.map(({ original }) => original.id);
        const titles = [];
        setData(
          data.map((row) => {
            if (ids.includes(row.id)) {
              titles.push(row.title);
              return {
                ...row,
                division,
              };
            }
            return row;
          })
        );

        setSchedule({
          ...schedule,
          ...[...new Set(titles)].reduce(
            (acc, value) => ({ ...acc, [value]: division }),
            {}
          ),
        });

        console.log({ titles, schedule });
      }
    },
    [selectedFlatRows, data, schedule, setSchedule]
  );

  const pagination = React.useMemo(
    () => (
      <Flex align="center" my="4" flexWrap="wrap">
        <Box my="2">
          <Button
            mx="1"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>{" "}
          <Button
            mx="1"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>{" "}
          <Button mx="1" onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>{" "}
          <Button
            mx="1"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </Box>
        <Flex align="center" minW="600px" my="2">
          <Text ml="4">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
            | Go to page:{" "}
          </Text>
          <Input
            ml="4"
            bg="white"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
          <Select
            ml="4"
            bg="white"
            w={200}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 30, 50, 100, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
    ),
    [
      canNextPage,
      canPreviousPage,
      gotoPage,
      nextPage,
      pageCount,
      pageIndex,
      pageOptions.length,
      pageSize,
      previousPage,
      setPageSize,
    ]
  );

  React.useEffect(() => {
    setSelectedMonthData(
      data.filter(({ start }) => {
        const startDate = new Date(start);
        return (
          startDate.getFullYear() === targetDate.getFullYear() &&
          startDate.getMonth() === targetDate.getMonth()
        );
      })
    );
  }, [data, targetDate]);

  const divisionList = React.useMemo(
    () => [
      "会議(部内)",
      "会議(社内)",
      "会議(社外)",
      "企画",
      "V3 保守・運用",
      "V2 保守・運用",
      "支援作業(SS)",
      "支援作業(CS)",
      "支援作業(その他)",
      "部内管理作業",
      "予算・物品購入関係作業",
      "契約関係作業",
      "採用関係作業",
      "移動時間",
    ],
    []
  );

  const resultStr = React.useMemo(() => {
    const filteredData = selectedMonthData.filter(
      ({ division }) => division !== "-" && division !== "除外"
    );
    if (filteredData.length > 0) {
      const days = eachDayOfInterval({
        start: startOfMonth(targetDate),
        end: lastDayOfMonth(targetDate),
      });
      const str = divisionList
        .flatMap((d) => (d === "V2 保守・運用" ? [d, ""] : d))
        .map((d) => {
          return days
            .map((day) => {
              const sumOfMin = filteredData
                .filter(
                  ({ division, start }) =>
                    division === d && isSameDay(day, new Date(start))
                )
                .map(({ start, end }) => {
                  return differenceInMinutes(new Date(end), new Date(start));
                })
                .reduce((acc, value) => acc + value, 0);
              if (sumOfMin > 0) {
                return format(addMinutes(day, sumOfMin), "HH:mm:SS");
              }
              return "";
            })
            .join("\t");
        })
        .join("\n");
      return str;
    }
    return "";
  }, [selectedMonthData, targetDate, divisionList]);

  return (
    <>
    <VStack bg="gray.50" minH="100vh">
      <Container maxW="1500px">
        <Heading mt="6" mb="2">
          勤怠ツール
        </Heading>
        <Text>
          予定を項目ごとに分類し、稼働管理に貼り付けるデータを出力します
        </Text>

        <Heading as="h2" mt="6" mb="2">
          使い方
        </Heading>
        <UnorderedList>
          <ListItem>
            Outlookから予定表をエクスポートします。(参考:{" "}
            <Link
              href="https://www.syscr.co.jp/blog/%E3%83%8D%E3%83%83%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF/office365/p3371/"
              color="teal.500"
              isExternal
            >
              Outlook予定表（カレンダー）をCSVで出力する方法
              <ExternalLinkIcon mx="2px" />
            </Link>
            )
          </ListItem>
          <ListItem>エクスポートしたCSVファイルをアップロードします。</ListItem>
          <ListItem>計算したい月を指定します。</ListItem>
          <ListItem>
            予定を選択し、対応する項目のボタンを押すことで分類されます。
            <br />
            フィルター、ソート、複数選択も可能なので一括で分類することができます。
            <br />
            同時刻の予定があった場合は関係なくどちらの時間も加算されます。
            <br />
            (除外ボタンは未分類の予定と分類に入れなくて良い予定を区別するためのものです。)
          </ListItem>
          <ListItem>
            一度分類した予定は、再度CSVをアップロードした際に自動で分類されます。
          </ListItem>
        </UnorderedList>
         <Heading as="h2" mt="6" mb="2">
          振り分けデータの移行
        </Heading>
        {window.location.hostname === 'kintai.hr20k.com' ? (
          <VStack alignItems='flex-start'>
            <Text>
              <Link
                href="https://kintai.mockht.net"
                color="teal.500"
                isExternal
              >
                kintai.mockht.net
                <ExternalLinkIcon mx="2px" />
              </Link>
              へ移行をお願いします。振り分けデータを移行先に読み込むことができます。
            </Text>
            <Button colorScheme="teal" onClick={async () => {
              await navigator.clipboard.writeText(localStorage.getItem('schedule')).then(
                () => {
                  /* clipboard successfully set */
                  setDataImportText('クリップボードに振り分けデータをコピーしました。')
                },
                () => {
                  /* clipboard write failed */
                  setDataImportText('クリップボードへのコピーに失敗しました')
                },
              );
            }}>
              クリップボードに振り分けデータをコピーする
            </Button>
            <Text>{dataImportText}</Text>
          </VStack>
        ) : window.location.hostname === 'kintai.mockht.net' ? (
          <VStack alignItems='flex-start'>
            <Text>
              振り分けデータを読み込みます。
            </Text>
            <Button colorScheme="teal" onClick={() => {
              importDataDialog.onOpen();
            }}>
              振り分けデータを読み込む
            </Button>
            <Text>{dataImportText}</Text>
          </VStack>
        ) : null}

        <CSVReader cssClass="react-csv-input" onFileLoaded={handleForce} />
        <Box>
          <Heading as="h3" fontSize="2xl" mt="6" mb="4">
            対象月
          </Heading>
          <DatePicker
            selected={targetDate}
            onChange={(date) => setTargetDate(date)}
            dateFormat="yyyy/MM"
            showMonthYearPicker
            showPopperArrow={false}
            customInput={<Input w="150px" bg="white" />}
          />
        </Box>
        <Box>
          <Heading as="h3" fontSize="2xl" mt="8" mb="4">
            出力データ
          </Heading>
          <Textarea
            name="result"
            bg="white"
            rows="6"
            readOnly
            value={resultStr}
          />
        </Box>
        <Heading as="h3" fontSize="2xl" mt="8" mb="4">
          予定の分類
        </Heading>
        <Box mb="2">
          {divisionList.map((value) => (
            <Button
              key={value}
              my="1"
              ml="2"
              _first={{ ml: "0" }}
              colorScheme="teal"
              onClick={() => handleClickDivisionButton(value)}
            >
              {value}
            </Button>
          ))}
        </Box>
        <Box mb="6">
          <Button
            colorScheme="teal"
            variant="outline"
            onClick={() => handleClickDivisionButton("除外")}
          >
            除外
          </Button>
          <Button
            ml="2"
            colorScheme="teal"
            variant="outline"
            onClick={() => handleClickDivisionButton("-")}
          >
            - (もとに戻す)
          </Button>
        </Box>
        {selectedMonthData.length > 0 && (
          <Box>
            {pagination}
            <Box bg="white" borderRadius="12" overflowX="auto">
              <Table {...getTableProps()}>
                <Thead>
                  {headerGroups.map((headerGroup) => (
                    <Tr
                      position={["-webkit-sticky", "sticky"]}
                      top="0"
                      zIndex={1}
                      bg="white"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        <Th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            maxWidth: column.maxWidth,
                          }}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? "↓"
                                : "↑"
                              : ""}
                          </span>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                  {headerGroups.map((headerGroup) => (
                    <Tr>
                      {headerGroup.headers.map((column) => (
                        <Th
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            maxWidth: column.maxWidth,
                          }}
                        >
                          <div>
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <Tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <Td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
            {pagination}
          </Box>
        )}
      </Container>
    </VStack>
    <Modal isOpen={importDataDialog.isOpen} onClose={importDataDialog.onClose}>
      <ModalOverlay />
        <ModalContent >
          <ModalHeader>
            <Text>振り分けデータを読み込む</Text>
          </ModalHeader>
          <ModalBody >
            <VStack gap={2} alignItems='flex-end'>
            <Textarea value={scheduleValue} onChange={(e) => setScheduleValue(e.target.value)} />
            <Button
              colorScheme="teal"
              onClick={() => {
                localStorage.setItem('schedule', scheduleValue);
                setDataImportText('振り分けデータを読み込みました。')
                importDataDialog.onClose();
                window.location.reload();
              }}
            >
              読み込む
            </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
    </Modal>
    </>
  );
};

export default App;
